<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <!-- <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                Back
                            </v-tooltip> -->
                            <v-toolbar-title>Organization Subscription</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                The organization subscription determines which features are available.
                            </p>

                            <p class="mb-0 pb-0 mt-6">
                                {{ organizationSubscriptionDisplay }}
                                <!-- system admin can directly change the subscription -->
                                <v-btn icon color="green darken-2" @click="dialogEditOrganizationSubscription = true" v-if="isPermitServiceAdmin">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                                </v-btn>
                            </p>
                            <!-- TODO: organization owners should be able to click a button here to upgrade/downgrade the subscription, with appropriate warnings about what might be lost when downgrading, and benefits they would get by upgrading -->
                        </v-card-text>
                    </v-card>
                    <v-dialog v-model="dialogEditOrganizationSubscription" max-width="600">
                        <v-card class="pa-0" max-width="600">
                            <v-toolbar dense flat color="white">
                                <v-toolbar-title class="green--text text--darken-2">Change Organization Subscription</v-toolbar-title>
                            </v-toolbar>
                            <!-- <v-divider class="mx-5 mb-10"></v-divider> -->
                            <!-- <v-card-text>
                                <p>Changes to this setting will not affect any products or services being sold to customers.</p>
                            </v-card-text> -->
                            <v-form @submit.prevent="editOrganizationSubscription" @keyup.enter.native="editOrganizationSubscription" class="px-5 mt-5">
                                <v-select v-model="editableOrganizationSubscription" :items="organizationSubscriptionChoices" label="Subscription" outlined dense ref="editableOrganizationSubscriptionInput"></v-select>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="green darken-2 white--text" @click="editOrganizationSubscription" :disabled="editableOrganizationSubscription === organization.subscription">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditOrganizationSubscription = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import TextLink from '@/components/TextLink.vue';
import { ORGANIZATION_SUBSCRIPTION_ESSENTIAL, ORGANIZATION_SUBSCRIPTION_BUSINESS, ORGANIZATION_SUBSCRIPTION_ENTERPRISE } from '@/sdk/constants';

export default {
    components: {
        // TextLink,
    },
    data: () => ({
        organization: null,
        error: null,

        organizationSubscriptionChoices: [
            { value: 'essential', text: 'Essential' },
            { value: 'business', text: 'Business' },
            { value: 'enterprise', text: 'Enterprise' },
        ],

        dialogEditOrganizationSubscription: false,
        editableOrganizationSubscription: null,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        organizationSubscriptionDisplay() {
            let displayText;
            switch (this.organization?.subscription) {
            case ORGANIZATION_SUBSCRIPTION_ESSENTIAL:
                displayText = 'Essential';
                break;
            case ORGANIZATION_SUBSCRIPTION_BUSINESS:
                displayText = 'Business';
                break;
            case ORGANIZATION_SUBSCRIPTION_ENTERPRISE:
                displayText = 'Enterprise';
                break;
            default:
                displayText = this.organization?.subscription;
                break;
            }
            return displayText;
        },
    },
    watch: {
        dialogEditOrganizationSubscription(newValue) {
            if (newValue) {
                this.editableOrganizationSubscription = this.organization.subscription;
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('editableOrganizationSubscriptionInput'); }, 1);
                });
            }
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async editOrganizationSubscription() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { editOrganizationSubscription: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.edit({ subscription: this.editableOrganizationSubscription });
                console.log(`editOrganizationSubscription: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.dialogEditOrganizationSubscription = false;
                    this.$set(this.organization, 'subscription', this.editableOrganizationSubscription);
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit subscription' });
                }
            } catch (err) {
                console.error('failed to edit susbscription', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit subscription' });
            } finally {
                this.$store.commit('loading', { editOrganizationSubscription: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
    },
};
</script>
